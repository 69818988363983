import { isServer } from '@/pages/_app'

const disabledMainScroll = () => {
  if (isServer) {
    return
  }
  document.body.style.overflow = 'hidden'
}

const ableMainScroll = () => {
  if (isServer) {
    return
  }
  document.body.style.overflow = 'unset'
}

export enum SafeAreaEnum {
  Top = '--safe-area-inset-top',
  Left = '--safe-area-inset-left',
  Right = '--safe-area-inset-right',
  Bottom = '--safe-area-inset-bottom'
}
export const getSafeArea = (type: SafeAreaEnum) =>
  parseFloat(getComputedStyle(document.documentElement).getPropertyValue(type))

const injectSafeArea = () => {
  const safeAreaInsets = {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  }
  // 뉴비오더 IOS: Native 앱에서 주입한 safeAreaInsets가 있습니다.
  if (window.safeAreaInsets) {
    safeAreaInsets.top = window.safeAreaInsets.top ?? 0
    safeAreaInsets.left = window.safeAreaInsets.left ?? 0
    safeAreaInsets.bottom = window.safeAreaInsets.bottom ?? 0
    safeAreaInsets.right = window.safeAreaInsets.right ?? 0
  } else {
    // 모바일 웹, 카카오 인앱 등 웹 브라우저는 env(safe-area-inset-*) 값을 넣습니다.
    safeAreaInsets.top = getSafeArea(SafeAreaEnum.Top)
    safeAreaInsets.left = getSafeArea(SafeAreaEnum.Left)
    safeAreaInsets.bottom = getSafeArea(SafeAreaEnum.Bottom)
    safeAreaInsets.right = getSafeArea(SafeAreaEnum.Right)
  }
  document.documentElement.style.setProperty('--safe-area-inset-top', safeAreaInsets.top + 'px')
  document.documentElement.style.setProperty('--safe-area-inset-left', safeAreaInsets.left + 'px')
  document.documentElement.style.setProperty('--safe-area-inset-bottom', safeAreaInsets.bottom + 'px')
  document.documentElement.style.setProperty('--safe-area-inset-right', safeAreaInsets.right + 'px')
}

export const StyleUtils = {
  ableMainScroll,
  disabledMainScroll,
  getSafeArea,
  injectSafeArea
}
