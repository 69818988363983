// 로컬스토리지에 데이터 저장
import { decrypt, encrypt } from '@/utils/localStorage'

export enum SessionStorageKeyEnum {
  BeforeRedirectUrl = 'BeforeRedirectUrl',
  ViewScroll = 'ViewScroll',
  IsFirstRenderRobotStatusSheet = 'IsFirstRenderRobotStatusSheet',
  ReopenVoucherSheet = 'ReopenVoucherSheet',
  VoucherCode = 'VoucherCode',
  IsEntryPointBanner = 'IsEntryPointBanner',
  SuccessReceivePoint = 'SuccessReceivePoint',
  beforeHistoryLength = 'beforeHistoryLength',
  OrderCreatedTime = 'OrderCreatedTime'
}

const sessionStorageSetItem = <T>(key: SessionStorageKeyEnum, data: T) => {
  // 데이터를 문자열로 변환하고 암호화하여 저장
  window.sessionStorage.setItem(key, encrypt(JSON.stringify(data)))
}

// 로컬스토리지에서 데이터 가져오기
const sessionStorageGetItem = <T>(key: SessionStorageKeyEnum) => {
  // 데이터를 가져와 복호화하고 원래의 타입으로 변환
  const encryptedData = window.sessionStorage.getItem(key)
  try {
    return encryptedData ? (JSON.parse(decrypt(encryptedData)) as T) : null
  } catch (e) {
    window.sessionStorage.clear()
    return null
  }
}

// 로컬스토리지에서 데이터 삭제
const sessionStorageRemoveItem = (key: SessionStorageKeyEnum) => {
  window.sessionStorage.removeItem(key)
}

const sessionStorageClearAll = () => {
  window.localStorage.clear()
}

export const SessionStorage = {
  getItem: sessionStorageGetItem,
  setItem: sessionStorageSetItem,
  removeItem: sessionStorageRemoveItem,
  clearAll: sessionStorageClearAll
}
