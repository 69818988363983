import { useEffect, useRef } from 'react'
import debounce from 'lodash/debounce'
import Router from 'next/router'
import { createContainer } from 'unstated-next'
import { SessionStorage, SessionStorageKeyEnum } from '@/utils/sessionStorage'

export type PageScrollInfoType = { [index: string]: number }

const useSaveScroll = () => {
  const isPopStateEvent = useRef<boolean>(false)

  const handleSaveScroll = debounce(() => {
    const currentScrollTop = document.documentElement.scrollTop
    const scrollMap = SessionStorage.getItem<PageScrollInfoType>(SessionStorageKeyEnum.ViewScroll)
    // 스크롤 맵이 있으면 페이지 스크롤 데이터 저장
    if (scrollMap) {
      scrollMap[Router.asPath] = currentScrollTop
      SessionStorage.setItem<PageScrollInfoType>(SessionStorageKeyEnum.ViewScroll, scrollMap)
      return
    }
  }, 200)

  const handleConfirmPopstate = () => {
    isPopStateEvent.current = true
  }

  useEffect(() => {
    // 브라우저 기본 스크롤 저장 기능 비활성화
    history.scrollRestoration = 'manual'
    const currentScrollTop = document.documentElement.scrollTop
    const scrollMap = SessionStorage.getItem<PageScrollInfoType>(SessionStorageKeyEnum.ViewScroll)

    // 스크롤 맵이 없으면 초기화
    if (!scrollMap) {
      const initScrollMap = {} as PageScrollInfoType
      initScrollMap[Router.asPath] = currentScrollTop
      SessionStorage.setItem<PageScrollInfoType>(SessionStorageKeyEnum.ViewScroll, initScrollMap)
    }

    window.addEventListener('scroll', handleSaveScroll)
    window.addEventListener('popstate', handleConfirmPopstate)
    return () => {
      window.removeEventListener('scroll', handleSaveScroll)
      window.removeEventListener('popstate', handleConfirmPopstate)
    }
  }, [])

  /**
   * 기본적으로 뒤로가기 동작시 이동된 페이지에 저장되어있던 스크롤을 복원합니다.
   *
   * @param allCase 뒤로가기 뿐만아니라 해당 페이지에 진입하면 스크롤을 복원합니다.
   */
  const handleRestoreScroll = (allCase: boolean | undefined = false) => {
    // popstate 이벤트가 걸리면 실행, allCase 플래그는 popState 이벤트가 없어도 실행
    if (allCase || isPopStateEvent.current) {
      const scrollMap = SessionStorage.getItem<PageScrollInfoType>(SessionStorageKeyEnum.ViewScroll)
      // 스크롤 맵이 있으면
      if (scrollMap) {
        const storeScroll = scrollMap[Router.asPath]
        storeScroll && window.scrollTo(0, storeScroll)
      }
    }
    // 초기 페이지 렌더시 스크롤 저장
    handleSaveScroll()
    isPopStateEvent.current = false
  }

  return {
    handleRestoreScroll
  }
}

const ScrollSaveContainer = createContainer(useSaveScroll)

export default ScrollSaveContainer
