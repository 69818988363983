import { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { useRouter } from 'next/router'
import { isIOS } from 'react-device-detect'
import { createContainer } from 'unstated-next'
import {
  useLoginSocialCreate,
  useLogoutCreate,
  useSignupSocialCreate,
  useUsersProfileRetrieve,
  useUsersWalletRetrieve
} from '@/api/generated/hooks'
import {
  CartCreate400ExceptionCodeEnum,
  DevicePushTokenReqRequest,
  SocialLoginRequest,
  SocialLoginRes,
  SocialLoginTypeEnum,
  SocialSignupRequest,
  TokenRefreshRes
} from '@/api/generated/types'
import {
  askAccessTokenUrl,
  customInstance,
  QueryStatusEnum,
  RequestListType,
  setStorageSiteName
} from '@/api/mutator/custom-instance'
import { QueryKeys, Routes } from '@/constants/routes'
import { RedirectURI } from '@/constants/social'
import { isInApp } from '@/pages/_app'
import { LocalStorage, LocalStorageKeyEnum } from '@/utils/localStorage'
import NativeBridgeAction from '@/utils/native-bridge/NativeBridgeAction'
import { SessionStorage, SessionStorageKeyEnum } from '@/utils/sessionStorage'

type SocialDataType = {
  refreshToken?: string
  socialLoginType: SocialLoginTypeEnum
}

export enum LoginStatusEnum {
  Login = 'Login',
  Logout = 'Logout',
  Loading = 'Loading',
  Init = 'Init'
}

export const askAccessToken = () => {
  const siteParam = setStorageSiteName()
  // accessToken 발급은 쿠키에 저장된 refreshToken으로 하기에 별도 데이터가 없어도 됩니다.
  return axios.post<TokenRefreshRes>(process.env.API_DOMAIN + askAccessTokenUrl, undefined, {
    withCredentials: true,
    headers: {
      ...(siteParam && { Site: siteParam })
    }
  })
}

const useAuthHook = () => {
  const tokenRefreshQueryKey = [askAccessTokenUrl]
  const queryClient = useQueryClient()
  const { replace, query } = useRouter()
  const [signInSocialData, setSignInSocialData] = useState<SocialDataType>()
  const [socialSignupData, setSocialSignupData] = useState<SocialSignupRequest>()
  const [loginStatus, setLoginStatus] = useState<LoginStatusEnum>(LoginStatusEnum.Init)
  const { mutateAsync: logout } = useLogoutCreate()
  const { data: loginConfirmRes, status: loginConfirmStatus } = useQuery({
    queryKey: tokenRefreshQueryKey,
    queryFn: askAccessToken,
    retry: 0,
    cacheTime: 0,
    enabled: loginStatus === LoginStatusEnum.Init
  })

  const { data: userProfile, isFetching: isUserProfileFetching } = useUsersProfileRetrieve({
    query: {
      enabled: loginStatus === LoginStatusEnum.Login
    }
  })

  // 유저 바우처
  const { data: userWallet, isFetching: isUserWalletFetching } = useUsersWalletRetrieve({
    query: {
      enabled: loginStatus === LoginStatusEnum.Login
    }
  })

  const clearUserInfoStorage = () => {
    LocalStorage.removeItem(LocalStorageKeyEnum.AccessToken)
    LocalStorage.removeItem(LocalStorageKeyEnum.AddCartComplete)
    LocalStorage.removeItem(LocalStorageKeyEnum.NonLoginRequestConfigList)
    SessionStorage.removeItem(SessionStorageKeyEnum.IsFirstRenderRobotStatusSheet)
  }

  useEffect(() => {
    if (loginConfirmStatus === QueryStatusEnum.Success) {
      LocalStorage.setItem(LocalStorageKeyEnum.AccessToken, loginConfirmRes.data.accessToken)
      setLoginStatus(LoginStatusEnum.Login)
      return
    }
    if (loginConfirmStatus === QueryStatusEnum.Loading) {
      setLoginStatus(LoginStatusEnum.Loading)
      return
    }
    if (loginConfirmStatus === QueryStatusEnum.Error) {
      setLoginStatus(LoginStatusEnum.Logout)
      clearUserInfoStorage()
      return
    }
  }, [loginConfirmStatus, loginConfirmRes])

  /**
   * 아래 로직은 로그아웃으로 isLogin이 false가 되었을 경우에만 queryClient.clear()를 실행되는 로직입니다.
   * 또한 isLogin이 확실하게 false로 동작 되어야만 동작시켜줘야 합니다.
   */
  useEffect(() => {
    const googleLoginSignout = () => {
      const socialLoginType = LocalStorage.getItem<SocialLoginTypeEnum>(LocalStorageKeyEnum.SocialLoginType)
      if (isInApp() && socialLoginType) {
        NativeBridgeAction.socialLogout({ socialLoginType })
      }
    }

    if (loginStatus === LoginStatusEnum.Logout && query?.[QueryKeys.LogoutFlag]) {
      /**
       * ⚠️ 주의!
       * 쿼리키가 삭제되었거나 invalidate 처리가 되었을 때 페이지에서 해당 데이터를 렌더링 하는 곳이 있다면
       * 렌더링 되는 값에 대한 쿼리가 곧바로 refetch 됩니다.
       */
      clearUserInfoStorage()
      queryClient.clear()
      googleLoginSignout()
    }
  }, [loginStatus, query])

  const logoutSequence = async (pushToken?: DevicePushTokenReqRequest, isWithdrawal?: boolean) => {
    if (isWithdrawal) {
      await replace({
        pathname: Routes.Home,
        query: {
          [QueryKeys.WithdrawalSuccess]: true
        }
      })
      setLoginStatus(LoginStatusEnum.Logout)
    } else {
      await logout({
        data: {
          deviceNumber: pushToken?.deviceNumber
        }
      })
      await replace({
        pathname: Routes.Login,
        query: {
          [QueryKeys.LogoutFlag]: true
        }
      })
      setLoginStatus(LoginStatusEnum.Logout)
    }
  }

  /**
   * Apple login은 local 환경에서 로그인 할 수 없습니다.
   * redirectUri가 가능한 환경은 neom-dev, neom-staging 입니다.
   * @link https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple
   */
  const signInApple = () => {
    if (isInApp() && isIOS) {
      NativeBridgeAction.socialLogin({ socialLoginType: SocialLoginTypeEnum.APPLE })
      return
    }
    const redirectUri = `${window?.location?.origin}/login/apple-callback`
    const clientId = process.env.APPLE_REST_KEY
    if (!redirectUri || !clientId) {
      console.error(`redirectUri or clientId not found. redirectUri: ${redirectUri}, clientId: ${clientId}`)
      return
    }
    const appleAuthUrl = 'https://appleid.apple.com/auth/authorize'
    location.replace(`${appleAuthUrl}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code`)
  }

  const signInGoogle = () => {
    if (isInApp()) {
      NativeBridgeAction.socialLogin({ socialLoginType: SocialLoginTypeEnum.GOOGLE })
      return
    }
    const redirectUri = `${window?.location?.origin}${RedirectURI.Google}`
    const clientId = process.env.GOOGLE_REST_KEY
    if (!redirectUri || !clientId) {
      console.error(`redirectUri or clientId not found. redirectUri: ${redirectUri}, clientId: ${clientId}`)
      return
    }
    const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth'
    const scope = 'https://www.googleapis.com/auth/userinfo.email'
    location.replace(
      `${googleAuthUrl}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&access_type=offline`
    )
  }

  const getHandleSuccessLoginSocialCreate = async (
    { accessToken }: SocialLoginRes,
    socialLoginType: SocialLoginTypeEnum
  ) => {
    LocalStorage.setItem(LocalStorageKeyEnum.AccessToken, accessToken)
    LocalStorage.setItem(LocalStorageKeyEnum.SocialLoginType, socialLoginType)
    setLoginStatus(LoginStatusEnum.Login)

    // 비로그인시 mutation을 시도해서 권한이 없어 실행되지 못한 mutation 리스트 실행
    const nonLoginRequestConfigList = LocalStorage.getItem<RequestListType>(
      LocalStorageKeyEnum.NonLoginRequestConfigList
    )

    try {
      if (nonLoginRequestConfigList) {
        Object.keys(nonLoginRequestConfigList).map((key) => {
          const values = nonLoginRequestConfigList[key]
          return customInstance(values.config, values.options)
        })
      }
    } catch (e: any) {
      // 이미 회원에 장바구니 데이터가 있는데 새로운 가게에서 상품을 담으려고 할 경우
      if (e.response.data.code === CartCreate400ExceptionCodeEnum.DIFFERENT_SHOP) {
        LocalStorage.setItem(LocalStorageKeyEnum.DifferentShop, true)
      }
    }
    LocalStorage.removeItem(LocalStorageKeyEnum.NonLoginRequestConfigList)

    // 로그인 전에 있던 화면으로 이동
    const redirectUrl = SessionStorage.getItem(SessionStorageKeyEnum.BeforeRedirectUrl)
    if (redirectUrl) {
      SessionStorage.removeItem(SessionStorageKeyEnum.BeforeRedirectUrl)
      replace(redirectUrl)
      return
    }

    // 홈에서 바로 로그인 화면으로 진입시 - 현재 마이페이지 진입후 에는 redirect 시퀀스를 탑니다.
    replace(Routes.Home)
  }

  const getHandleErrorLoginSocialCreate = (
    error: AxiosError,
    socialLoginType: SocialLoginTypeEnum,
    clientId?: string | null
  ) => {
    setLoginStatus(LoginStatusEnum.Logout)
    LocalStorage.removeItem(LocalStorageKeyEnum.AccessToken)
    LocalStorage.removeItem(LocalStorageKeyEnum.SocialLoginType)
    const response = error.response
    /**
     * 리프레시 토큰 발급이후 회원 정보가 존재하지 않을 경우, 서버에서는 리프레시 토큰 값과 함께 404 에러로 응답합니다
     * 향후 에러 코드 및 에러 타입에 대한 정의가 이뤄진 후 리팩토링이 필요합니다
     * 혹은 리프레시 토큰 값을 생성하는 API를 별도 생성하여 처리할 경우 에러 타입에 대한 정의 없이도 회원가입 페이지로 넘어가는 동작을 구현할 수 있습니다
     */
    if (response?.status === 404) {
      // 회원 정보 없을 경우 서버에서 404 에러로 응답함
      const result = response?.data as AxiosResponse<SocialSignupRequest> // 회원가입 요청을 위한 정보가 response에 담김
      const { refreshToken, refreshTokenExpiresIn, idToken, code, redirectUri } = result.data
      setSocialSignupData({
        socialLoginType,
        refreshToken,
        refreshTokenExpiresIn,
        idToken,
        code,
        redirectUri,
        clientId
      })
      replace(Routes.Terms)
    } else if (response?.status === 400) {
      const errorData = response?.data as { code: string; detail: string }
      // 추후 error Exception 제너레이트 되면 수정하여 사용합니다.
      const UserAlreadyLeft = 'USER_ALREADY_LEFT'
      if (errorData.code === UserAlreadyLeft) {
        replace({
          pathname: Routes.Login,
          query: {
            [QueryKeys.ReSignup]: true
          }
        })
        return
      }
      replace({
        pathname: Routes.Login,
        query: {
          [QueryKeys.ReSignup]: true
        }
      })
    } else {
      replace(Routes.Login)
    }
  }

  const { mutateAsync: createLoginSocial, isLoading: isLoginSocialLoading } = useLoginSocialCreate({
    mutation: {
      onSuccess: (data, variables) => getHandleSuccessLoginSocialCreate(data, variables.data.socialLoginType),
      onError: (data, variables) =>
        getHandleErrorLoginSocialCreate(data, variables.data.socialLoginType, variables.data.clientId)
    }
  })

  const loginSocialApple = async (code: string, clientId: string) => {
    if (!process.env.APPLE_REST_KEY) {
      console.error('apple key not found')
      return
    }

    const socialData: SocialLoginRequest = {
      clientId,
      socialLoginType: SocialLoginTypeEnum.APPLE,
      code: code
    }
    await createLoginSocial({ data: socialData })
  }

  const loginSocialGoogle = async (code: string) => {
    if (!process.env.GOOGLE_REST_KEY) {
      console.error('google key not found')
      return
    }

    const socialData: SocialLoginRequest = {
      socialLoginType: SocialLoginTypeEnum.GOOGLE,
      code: code,
      redirectUri: `${window?.location?.origin}${RedirectURI.Google}`
    }
    await createLoginSocial({ data: socialData })
  }

  // 회원가입 signUp
  const { mutate: signupSocialMutate } = useSignupSocialCreate({
    mutation: {
      onSuccess: (data, variables) => {
        LocalStorage.setItem(LocalStorageKeyEnum.AccessToken, data.accessToken)
        LocalStorage.setItem(LocalStorageKeyEnum.SocialLoginType, variables.data.socialLoginType)
        setLoginStatus(LoginStatusEnum.Login)
        replace(Routes.RegisterFinish)
      },
      onError: (error) => {
        console.error(error)
      }
    }
  })

  // 회원가입 요청
  const signupSocial = () => {
    if (!socialSignupData) {
      return
    }
    signupSocialMutate({ data: { ...socialSignupData } })
  }

  return {
    logoutSequence,
    signInApple,
    signInGoogle,
    signInSocialData,
    setSignInSocialData,
    loginStatus,
    setLoginStatus,
    loginSocialApple,
    loginSocialGoogle,
    isLoginSocialLoading,
    signupSocial,
    socialSignupData,
    userProfile,
    isUserProfileFetching,
    userWallet,
    isUserWalletFetching
  }
}

const AuthContainer = createContainer(useAuthHook)

export default AuthContainer
