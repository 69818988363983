import { createContainer } from 'unstated-next'
import useToast from '@/hooks/common/useToast'

const GlobalToastContainer = createContainer(() => {
  const toastControl = useToast()
  return {
    toastControl
  }
})

export default GlobalToastContainer
