import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import { QueryClient } from '@tanstack/query-core'
import { Hydrate, QueryCache, QueryClientProvider } from '@tanstack/react-query'
import { NextPage } from 'next'
import Head from 'next/head'
import { appWithTranslation } from 'next-i18next'
import { isAndroid, isIOS } from 'react-device-detect'
import Layout from '@/components/layouts/Layout'
import RootMetaTags from '@/components/meta/RootMetaTags'
import { DeployEnvironmentEnum } from '@/constants/deployEnvironment.enum'
import GlobalToastContainer from '@/containers/GlobalToastContainer'
import ScrollSaveContainer from '@/containers/ScrollSaveContainer'
import AuthContainer from '@/containers/app/AuthContainer'
import NativeBridgeContainer, { nativeBridgeSetup } from '@/containers/app/NativeBridgeContainer'
import { LocalStorage, LocalStorageKeyEnum } from '@/utils/localStorage'
import type { AppProps } from 'next/app'
import '../styles/globals.css'

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

export const isSite = (slug?: string | null) => {
  if (isServer) {
    return false
  }
  return LocalStorage.getItem(LocalStorageKeyEnum.SiteSlug) === slug
}
export const isServer = typeof window === 'undefined'

export const isInApp = () => navigator.userAgent.indexOf('neubility-app') > -1

export const isHidingFinancialDealTerms = () => {
  return process.env.NEXT_PUBLIC_HIDE_FINACIAL_DEAL_TERMS === 'true'
}

const runMockWorker = async () => {
  const { worker } = await import('@/api/mock/mockWorker')
  worker?.start?.()
}

if (!isServer && process.env.MOCK_ENABLE) {
  runMockWorker()
}

// native bridge 설정
if (isIOS || isAndroid) {
  nativeBridgeSetup()
}

const ReactQueryDevtoolsProduction = React.lazy(() =>
  // eslint-disable-next-line import/extensions
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then((d) => ({
    default: d.ReactQueryDevtools
  }))
)

function App({ Component, pageProps }: AppPropsWithLayout) {
  const queryCache = new QueryCache()
  const [showDevtools, setShowDevtools] = useState(false)

  /**
   * 기본 cacheTime을 0으로 두면 serverside hydration이 되기전에 api 요청후 리렌더링 되므로
   * 이중렌더링 될 수 있습니다.
   */
  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache,
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            staleTime: 0,
            retryDelay: 300
          }
        }
      })
  )

  useEffect(() => {
    if (process.env.NEWBIE_ORDER_ENV !== DeployEnvironmentEnum.Production) {
      setShowDevtools(true)
    }
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps && pageProps.dehydratedState}>
        {showDevtools && (
          <React.Suspense fallback={null}>
            <ReactQueryDevtoolsProduction position="bottom-right" />
          </React.Suspense>
        )}
        <AuthContainer.Provider>
          <NativeBridgeContainer.Provider>
            <ScrollSaveContainer.Provider>
              <GlobalToastContainer.Provider>
                <Head>
                  <RootMetaTags />
                </Head>
                <Layout>
                  <Component {...pageProps} />
                </Layout>
              </GlobalToastContainer.Provider>
            </ScrollSaveContainer.Provider>
          </NativeBridgeContainer.Provider>
        </AuthContainer.Provider>
      </Hydrate>
    </QueryClientProvider>
  )
}

export default appWithTranslation(App)
