/*eslint sort-keys: ["error", "asc", {caseSensitive: false}]*/
export const RouteKeys = {
  Cabin: 'cabin',
  Cart: 'cart',
  Error404: '404',
  Finish: 'finish',
  Id: 'id',
  Index: '/',
  InformPermissions: 'inform-permissions',
  Login: 'login',
  MyPage: 'my-page',
  Order: 'order',
  OrderHistory: 'order-history',
  PayCallback: 'pay-callback',
  PayFailed: 'pay-failed',
  PaymentCard: 'payment-card',
  PaymentMethod: 'payment-method',
  Policy: 'policy',
  Product: 'product',
  ProductId: 'productId',
  Register: 'register',
  RegisterFinish: 'register-finish',
  Shop: 'shop',
  ShopId: 'shopId',
  Sites: 'sites',
  TapPayCallback: 'tap-pay-callback',
  Terms: 'terms',
  VerifyEmail: 'verify-email',
  VerifyMobile: 'verify-mobile',
  Withdrawal: 'withdrawal'
}

export const QueryKeys = {
  beforeHistoryLength: 'beforeHistoryLength',
  ImpSuccess: 'imp_success',
  LogoutFlag: 'logout-flag',
  MerchantUid: 'merchant_uid',
  OrderID: 'order_id',
  OrderNotificationSubscriptionSuccess: 'order-notification-subscription-success',
  OrderNumber: 'order-number',
  Pin: 'pin',
  PushType: 'push_type',
  Redirect: 'redirect_to',
  RefreshExpired: 'refresh-expired',
  ReSignup: 're-signup',
  ScenarioNumber: 'scenario-number',
  SignUpFail: 'signup-fail',
  SiteSlug: 'site',
  WalletPaymentSuccess: 'wallet_payment_success',
  WithdrawalSuccess: 'withdrawal-success'
}

export const Routes = {
  Cabin: `/${RouteKeys.Cabin}`,
  Cart: `/${RouteKeys.Cart}`,
  Error404: `/${RouteKeys.Error404}`,
  Home: `/`,
  InformPermissions: `/${RouteKeys.InformPermissions}`,
  Login: `/${RouteKeys.Login}`,
  MyPage: {
    Index: `/${RouteKeys.MyPage}`,
    PaymentCard: {
      List: `/${RouteKeys.MyPage}/${RouteKeys.PaymentCard}`,
      Register: `/${RouteKeys.MyPage}/${RouteKeys.PaymentCard}/${RouteKeys.Register}`
    }
  },
  Order: {
    Index: `/${RouteKeys.Order}`,
    TapPayCallback: `/${RouteKeys.Order}/${RouteKeys.TapPayCallback}`
  },
  OrderFinish: `/${RouteKeys.Order}/${RouteKeys.Finish}`,
  OrderHistory: `/${RouteKeys.OrderHistory}`,
  OrderHistoryDetail: `/${RouteKeys.OrderHistory}/[${RouteKeys.Id}]`,
  OrderPayCallback: `/${RouteKeys.Order}/${RouteKeys.PayCallback}`,
  OrderPayFailed: `/${RouteKeys.Order}/${RouteKeys.PayFailed}`,
  OrderPaymentMethod: `/${RouteKeys.Order}/${RouteKeys.PaymentMethod}`,
  RegisterFinish: `/${RouteKeys.RegisterFinish}`,
  Shop: {
    Index: `/${RouteKeys.Shop}/[${RouteKeys.ShopId}]`,
    Product: `/${RouteKeys.Shop}/[${RouteKeys.ShopId}]/${RouteKeys.Product}/[${RouteKeys.ProductId}]`
  },
  Sites: `/${RouteKeys.Sites}`,
  Terms: `/${RouteKeys.Terms}`,
  VerifyEmail: `/${RouteKeys.VerifyEmail}`,
  VerifyMobile: `/${RouteKeys.VerifyMobile}`,
  Withdrawal: `/${RouteKeys.Withdrawal}`
}
