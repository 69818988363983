var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"94f0e4b9e4d141b1ce26a4293a57ac156f6756c3"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

// 프론트 클라이언트 사이드 설정
Sentry.init({
  dsn: process.env.SENTRY_DSN,
  sampleRate: 0.1, // 에러 이벤트를 캡쳐 (10%)
  tracesSampleRate: 0.1, // 10%의 트랜잭션을 트레이싱 (10%)
  beforeSend(event) {
    return event
  }
})
