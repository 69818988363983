import { ReactNode, useEffect, useRef, useState } from 'react'

type ToastDataType = {
  id: number
  content: ReactNode
  isPermanent: boolean
}

export type ToastControlsType = {
  toastListData?: ToastDataType[]
  addToast(content: ReactNode, isPermanent?: boolean): void
  removeToastById(targetId: number): void
}

const useToast = (): ToastControlsType => {
  const [toastListData, setToastListData] = useState<ToastDataType[]>()
  const isAdd = useRef(false)
  const toastId = useRef(0)
  const isPermanentRef = useRef(false)

  useEffect(() => {
    if (!isAdd.current) {
      return
    }
    setTimeout(() => {
      setToastListData((prev) => {
        if (prev) {
          const result = [...prev]
          const firstIndex = result.findIndex((toast) => !toast.isPermanent)
          if (firstIndex > -1) {
            result.splice(firstIndex, 1)
          }
          return result
        }
        return undefined
      })
    }, 3000)
    isAdd.current = false

    return () => {
      isPermanentRef.current = false
    }
  }, [toastListData])

  const getNextToastId = () => {
    const id = toastId.current
    toastId.current += 1
    return id
  }

  const addToast = (content: ReactNode, isPermanent?: boolean) => {
    isAdd.current = true
    isPermanentRef.current = isPermanent ?? false
    const id = getNextToastId()
    const toastData = { id, content, isPermanent: isPermanent ?? false }
    setToastListData((prev) => {
      if (prev) {
        return [...prev, toastData]
      }
      return [toastData]
    })
  }

  const removeToastById = (targetId: number) => {
    isAdd.current = false
    setToastListData((prev) => {
      return prev?.filter((item) => item.id !== targetId)
    })
  }

  return { toastListData, addToast, removeToastById }
}

export default useToast
