import Axios, { AxiosError, AxiosRequestConfig } from 'axios'
import Router from 'next/router'
import { CamelCase } from 'type-fest'
import { tokenRefreshCreate } from '@/api/generated/hooks'
import { QueryKeys, Routes } from '@/constants/routes'
import { isServer } from '@/pages/_app'
import { devDomains } from '@/utils/beforeGetServerSideProps'
import { LocalStorage, LocalStorageKeyEnum } from '@/utils/localStorage'
import { PromiseLocker } from '@/utils/promiseLocker'
import { SessionStorage, SessionStorageKeyEnum } from '@/utils/sessionStorage'

export const askAccessTokenUrl = '/token/refresh/'
export type RequestListType = { [index: string]: { config: AxiosRequestConfig; options?: AxiosRequestConfig } }
const mutateRequestConfigList: RequestListType = {}

export const AXIOS_INSTANCE = Axios.create({ baseURL: process.env.API_DOMAIN }) // use your own URL here or environment variable

export const setStorageSiteName = () => {
  // 서버사이드 경우 return
  if (isServer) {
    return
  }

  const hostSlug = window?.location?.hostname?.split('.')?.[0]
  const paramSlug = new URLSearchParams(window.location.search).get(QueryKeys.SiteSlug)

  /**
   * siteParam으로 준 것이 로컬 스토리지 보다 우선되어야 합니다.
   * hostSlug가 devDomain이여도 param 이 있으면 반환
   */
  if (paramSlug) {
    LocalStorage.setItem(LocalStorageKeyEnum.SiteSlug, paramSlug)
    return paramSlug as string
  }

  if (LocalStorage.getItem<string>(LocalStorageKeyEnum.SiteSlug)) {
    return LocalStorage.getItem<string>(LocalStorageKeyEnum.SiteSlug)
  }

  /**
   * hostSlug가 devDomain 인데 paramSlug, localStorage Slug가 없으면 undefined 반환
   * hostSlug가 있으면 hostSlug 반환
   */
  if (hostSlug) {
    if (devDomains.includes(hostSlug)) {
      return
    }
    LocalStorage.setItem(LocalStorageKeyEnum.SiteSlug, hostSlug)
    return hostSlug
  }
  return
}

const getAccessToken = () => {
  if (isServer) {
    return
  }
  const accessToken = LocalStorage.getItem(LocalStorageKeyEnum.AccessToken)
  if (!accessToken) return
  return accessToken
}
const promiseLock = PromiseLocker()

// add a second `options` argument here if you want to pass extra options to each generated query
export const customInstance = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
  const siteSlug = setStorageSiteName()
  const accessToken = getAccessToken()

  const source = Axios.CancelToken.source()
  const instanceConfig = {
    ...options,
    ...config,
    cancelToken: source.token,
    withCredentials: true,
    headers: {
      ...config?.headers,
      ...(siteSlug && { Site: siteSlug }),
      ...(accessToken && { Authorization: `Bearer ${accessToken}` })
    }
  }
  if (instanceConfig.url && instanceConfig.method !== 'get') {
    mutateRequestConfigList[instanceConfig.url] = { config, options }
  }
  return AXIOS_INSTANCE(instanceConfig).then(({ data }) => data)
}

AXIOS_INSTANCE.interceptors.response.use(
  function (response) {
    return response
  },
  async (error) => {
    // accessToken 만료
    if (error.response.status === 403 && !isServer) {
      /**
       * 로그인 권한이 없어 실패한 Mutation 설정 LocalStorage 저장
       * ex) 카트에 물건을 담으면 로그인 시퀀스 진행 -> 로그인 하고 돌아오면 물건 담겨 있음
       */
      const nonLoginRequestConfigList: RequestListType =
        LocalStorage.getItem<RequestListType>(LocalStorageKeyEnum.NonLoginRequestConfigList) ?? {}
      nonLoginRequestConfigList[error.config.url] = mutateRequestConfigList[error.config.url]
      LocalStorage.setItem(LocalStorageKeyEnum.NonLoginRequestConfigList, nonLoginRequestConfigList)

      // 여러 api 요청이 accessToken 만료가 되었을 때  promiseLock 으로 한번만 accessToken 요청
      if (promiseLock.lock()) {
        LocalStorage.removeItem(LocalStorageKeyEnum.AccessToken)
        const tokenRes = await tokenRefreshCreate()
        LocalStorage.setItem(LocalStorageKeyEnum.AccessToken, tokenRes.accessToken)
        promiseLock.release()
      } else {
        await promiseLock.waitUnLock()
      }
    }
    // refreshToken 만료
    if (error.response.config.url === askAccessTokenUrl && error.response.status === 401 && !isServer) {
      SessionStorage.setItem(SessionStorageKeyEnum.BeforeRedirectUrl, Router.asPath)
      LocalStorage.removeItem(LocalStorageKeyEnum.AccessToken)
      Router.replace({
        pathname: Routes.Login,
        query: {
          [QueryKeys.RefreshExpired]: true
        }
      })
    }
    if (error.response.status === 499 && !isServer) {
      // header에 site가 없거나 유효하지 않으면 localStorage 삭제 후 사이트 리스트로
      LocalStorage.removeItem(LocalStorageKeyEnum.SiteSlug)
      Router.replace(Routes.Sites)
    }
    return Promise.reject(error)
  }
)

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>
// In case you want to wrap the body type (optional)
// (if the custom instance is processing data before sending it, like changing the case for example)
export type BodyType<BodyData> = CamelCase<BodyData>

export enum QueryStatusEnum {
  Error = 'error',
  Loading = 'loading',
  Success = 'success'
}

export enum FetchStatusEnum {
  Fetching = 'fetching',
  Paused = 'paused',
  Idle = 'idle'
}

/**
 * Idle: mutation을 한번도 보낸적 없는 상태
 */
export enum MutateStatusEnum {
  Idle = 'idle',
  Error = 'error',
  Loading = 'loading',
  Success = 'success'
}
